interface Artwork {
    id: string;
    hidden: boolean;
    availableOn: Date | undefined;
    title: string;
    imageURL: string;
    note: string | undefined;
    description: string | undefined;
    info: string | undefined;
}

const validKeys = ["id", "hidden", "available", "title", "image", "description", 
"note", "info"];

type Line = { text: string, number: number };

class ArtworkError extends Error {
    constructor(message: string, line: Line, after: boolean) {
        const cooked = `
        
        Error ${ after ? "after" : "on"} line ${line.number}: ${message}
        ${line.text}
        
        `;
        super(cooked);
    }
}

function splitFile(file: string) {
    const works: Line[][] = [];
    let work: Line[] = [];
    file
        .split("\n")
        .map((text, index) => ({ text, number: index + 1 }))
        .forEach((line) => {
            if (line.text.trim() === "---") {
                works.push(work);
                work = [];
            } else if (line.text.trim().length > 0 && !line.text.trim().startsWith("---")) {
                work.push(line);
            }
        });
    works.push(work);
    return works.filter(work => work.length > 0);
}

type Attribute = { key: string, value: string, line: Line };

export function parseArtworkFile(file: string): Artwork[] {
    return splitFile(file)
        .map((workDescription) => {
            const attributes = new Map<string, Attribute>(
                workDescription
                .map((line) => {
                    const delimeterIndex = line.text.indexOf(":");
                    const key = line.text.substring(0, delimeterIndex).trim();
                    return [
                        key, 
                        {
                            value: line.text.substring(delimeterIndex + 1).trim(),
                            line,
                            file,
                            key,
                        }
                    ];
                })
            );
            Array.from(attributes.values()).forEach(attribute => {
                if (!validKeys.includes(attribute.key)) {
                    if (attribute.line.text.indexOf(":") === -1) {
                        throw new ArtworkError(
                            `Line has no key (missing colon?)`, attribute.line, false
                        );
                    } else {
                        throw new ArtworkError(
                            `Invalid key: '${attribute.key}'`, attribute.line, false
                        );
                    }
                }
            });
            const lastLine = workDescription[workDescription.length - 1];
            return {
                id: parseId(attributes.get("id"), lastLine),
                hidden: parseHidden(attributes.get("hidden")),
                availableOn: parseAvailableOn(attributes.get("available")),
                title: parseTitle(attributes.get("title"), lastLine),
                imageURL: parseImageURL(attributes.get("image"), lastLine),
                note: attributes.get("note")?.value,
                info: attributes.get("info")?.value,
                description: attributes.get("description")?.value
            };
        });
}

function isValidDate(d: Date) {
    return d instanceof Date && !isNaN(d.getTime());
  }

function parseAvailableOn(attribute: Attribute | undefined): Date | undefined {
    if (attribute === undefined) {
        return undefined;
    } else {
        try {
            const date = new Date(attribute.value);
            if (!isValidDate(date)) {
                throw new Error("Invalid date");
            }
            return date;
        } catch (error) {
            throw new ArtworkError(`Error parsing 'date' '${attribute.value}': ${error}`, attribute.line, false)
        }
    }
}

function parseTitle(attribute: Attribute | undefined, lastLine: Line) {
    if (attribute) {
        return attribute.value;
    } else {
        throw new ArtworkError("Missing 'title'", lastLine, true);
    }
}

function parseImageURL(attribute: Attribute | undefined, lastLine: Line) {
    if (attribute) {
        return attribute.value;
    } else {
        throw new ArtworkError("Missing 'image'", lastLine, true);
    }
}

function parseId(attribute: Attribute | undefined, lastLine: Line) {
    if (attribute) {
        return attribute.value;
    } else {
        throw new ArtworkError("Missing ID", lastLine, true);
    }
}

function parseHidden(attribute: Attribute | undefined) {
    if (attribute === undefined) {
        return false;
    }
    if (attribute.value.toLocaleLowerCase() === "false") {
        return false;
    }
    if (attribute.value.toLocaleLowerCase() === "no") {
        return false;
    }
    if (attribute.value.toLocaleLowerCase() === "true") {
        return true;
    }
    if (attribute.value.toLocaleLowerCase() === "yes") {
        return true;
    }
    throw new ArtworkError(`Invalid value for 'hidden': '${attribute.value}' (expected 'yes' or 'no')`, attribute.line, false);
}