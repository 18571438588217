import styled from "styled-components"
import { Footer, Header, HeaderTitle, Page, Link, TopButtonRow, ButtonLink, DumbLink } from "./common"
import portrait from "./assets/img/portrait.jpg";

export const Contact: React.FC = () => {
    return <div>
        <Header>
            <HeaderTitle>The Happy World of Hoodleoos</HeaderTitle>
        </Header>
        <Page>
            <TopButtonRow>
                <ButtonLink 
                    to="/"
                >Home</ButtonLink>
                <ButtonLink 
                    to="/about"
                >About</ButtonLink>
                <ButtonLink 
                    to="/archive"
                >Archive</ButtonLink>
                <ButtonLink to="/book">Book</ButtonLink>
            </TopButtonRow>
            <Container>
                <Paragraph>
                    <img src={portrait} alt="Illustration of the Artist" width="300px" style={{ margin: "5px" }}/>
                    <Thing>
                        <div><b>Peter’s music website:</b> <DumbLink href="peterdaytonmusic.com">peterdaytonmusic.com</DumbLink></div>
                        <div><b>Email:</b> happyworldofhoodleoos[at]gmail.com</div>
                    </Thing>
                </Paragraph>
            </Container>
        </Page>
        <Footer>
            © Copyright Peter Dayton, 2022-2024 |&nbsp;<Link to="/">Home</Link>
        </Footer>
    </div>
}

const Paragraph = styled.div`
    font-family: EB Garamond;
    line-height: 2em;
    margin: 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    div { text-align: center; }
    gap: 20px;

    @media screen and (max-width: 780px) {
        flex-direction: column;
    }
`;

export const Thing = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const Container = styled.div`
    --width: min(calc(100vw - 40px), 1000px);
    display: flex;
    width: var(--width);
    margin: 10px 10px 2vh 10px;
    justify-content: center;
`;