import { useParams, useNavigate, Navigate } from "react-router-dom"
import styled from "styled-components";
import { Footer, Header, HeaderTitle, Page, Link, Button, TopButtonRow, ButtonLink, DumbButtonLink } from "./common";
import { usePosts } from "./use_posts";

function randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function page(id: string) {
    return `/${id}`;
}

export const Home: React.FC = () => {
    const { postId } = useParams();
    const { posts, post, links } = usePosts(postId);
    const navigate = useNavigate();

    if (posts && post === undefined) {
        return <Navigate to="/" />;
    }

    const imgSrc = process.env.PUBLIC_URL + "/artwork/" + post?.imageURL;
    return <div>
        <Header>
            <HeaderTitle>The Happy World of Hoodleoos</HeaderTitle>
        </Header>
        { post && <Page>
            <TopButtonRow>
                <ButtonLink 
                    to="/about"
                >About</ButtonLink>
                <ButtonLink 
                    to="/archive"
                >Archive</ButtonLink>
                <ButtonLink 
                    to="/contact"
                >Contact</ButtonLink>
                <ButtonLink
                    to="/book"
                >Book</ButtonLink>
            </TopButtonRow>
            <Container>
                <OuterOuterFrame>
                    <InnerOuterFrame>
                        <OuterInnerFrame>
                            <InnerInnerFrame>
                                <Painting 
                                    src={imgSrc} 
                                    alt={post?.description || post?.title} 
                                />
                            </InnerInnerFrame>
                        </OuterInnerFrame>
                    </InnerOuterFrame>
                </OuterOuterFrame>
            </Container>
            <TitleRow>
                <h1>{post?.title || " "}</h1>
                { post?.note && <Note>{post?.note}</Note>}
            </TitleRow>
            <ButtonRow>
                <Button 
                    onClick={() => links?.first && navigate(page(links?.first.id))}
                    disabled={!links?.previous}
                >First</Button>
                <Button 
                    onClick={() => links?.previous && navigate(page(links?.previous.id))}
                    disabled={!links?.previous}
                >Prev.</Button>
                <Button 
                    onClick={() => posts && navigate(page(posts[randomNumber(0, posts.length - 1)].id))}
                >Random</Button>
                <Button 
                    onClick={() => links?.next && navigate(page(links?.next.id))}
                    disabled={!links?.next}
                >Next</Button>
                <Button 
                    onClick={() => links?.last && navigate(page(links?.last.id))}
                    disabled={!links?.next}
                >Last</Button>
            </ButtonRow>
            <ButtonRow style={{marginTop: "20px"}}>
                <DumbButtonLink 
                    href="https://docs.google.com/forms/d/e/1FAIpQLScDGUCtxkVZnrz2BXsbUmlZDtfaaQZhw_jW-3PiIvHM8hljpg/viewform?usp=sf_link
                    https://docs.google.com/forms/d/e/1FAIpQLScDGUCtxkVZnrz2BXsbUmlZDtfaaQZhw_jW-3PiIvHM8hljpg/viewform?usp=sf_link"
                    target="_blank"
                >Subscribe</DumbButtonLink>
            </ButtonRow>
        </Page>}
        <Footer>
            © Copyright Peter Dayton, 2022-2024 |&nbsp;<Link to="/about">About</Link>
        </Footer>
    </div>
}

const Note = styled.p`
    color: #74747b;
    font-size: 16px;
    font-family: EB Garamond;
    margin: 4px 0;
`;

const TitleRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    color: #515154;

    h1 {
        margin: 0;
        margin-top: 10px;
        font-size: 20px;

        color: #515154;
        font-family: Jost;
    }
`

const Painting = styled.img`
    width: 100%;
    margin: 0;
    vertical-align: middle;
    horizontal-align: middle;
`;

const OuterOuterFrame = styled.div`
    width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    border: 1px solid #dddddd;
    border-radius: 1px;
    background-color: #e7e5e5;
    box-shadow: 1px 16px 30px #91919145;
    display: flex;
    justify-content: center;
    height: 100%;
`;

const InnerOuterFrame = styled.div`
    width: calc(100% - 4%);
    margin: 2%;
    background-color: #f7f4f4;
    border: 1px solid #e8e8e6;
    border-radius: 1px;
    box-shadow: 0px 0px 17px #91919182 inset;
    display: flex;
    justify-content: center;
`;

const OuterInnerFrame = styled.div`
    width: calc(100% - 10%);
    margin: 5%;
    border-radius: 1px;
    border: 1px solid #efefef;
    background-color: #efefef;
    box-shadow: 1px 1px 8px #91919182;
    display: flex;
    justify-content: center;
`;

const InnerInnerFrame = styled.div`
    width: calc(100% - 2%);
    margin: 1%;
    border: 1px solid #9191914d;
    border-radius: 1px;
    box-shadow: 1px 1px 8px #91919182 inset;
    display: flex;
    justify-content: center;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;
`;


export const Container = styled.div`
    --width: min(100vw, 1000px, 0.8 * (100vh - 70px));
    display: flex;
    width: var(--width);
    margin: 10px 10px 2vh 10px;
    justify-content: center;
`;