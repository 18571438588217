import styled from "styled-components"
import { Footer, Header, HeaderTitle, Page, Link, TopButtonRow, ButtonLink, DumbLink} from "./common"
import herbert from "./assets/img/herbert-title-page.jpg";
import book from "./assets/img/book-sample.jpg";
export const Book: React.FC = () => {
    return <div>
        <Header>
            <HeaderTitle>The Happy World of Hoodleoos</HeaderTitle>
        </Header>
        <Page>
            <TopButtonRow>
                <ButtonLink 
                    to="/"
                >Home</ButtonLink>
                <ButtonLink
                    to="/about"
                >About</ButtonLink>
                <ButtonLink 
                    to="/archive"
                >Archive</ButtonLink>
                <ButtonLink 
                    to="/contact"
                >Contact</ButtonLink>
            </TopButtonRow>
            <Container>
                <Title>
                   A Trip to the Art Museum with Herbert the Hoodleoo
                </Title>
                <Paragraph>
                    <ImgContainer><img src={herbert} alt="A Trip to the Art Museum with Herbert the Hoodleoo" width="400px" style={{ margin: "5px" }}/></ImgContainer>
                    <Thing>
                    <div>Published in January 2024, <b>A Trip to the Art Museum with Herbert the Hoodleoo</b> is Peter Dayton's debut full-length storybook featuring the Happy World of Hoodleoos. Herbert, a friendly hoodleoo in a driver's cap and matching cardigan, guides the reader as his guest to the city art museum using the bus system. He tours the art museum and discusses the museum and its relationship to its community. The story concludes with Herbert's return home and a reflection on art. Some of the themes involved include public transit, physical accessibility, the potential for a museum as a locus of artmaking and community, and presenting an unintimidating vision of artmaking.</div>
                    </Thing>
                </Paragraph>
                <Paragraph>
                Currently, <b>A Trip to the Art Museum with Herbert the Hoodleoo</b> is available for purchase in the gift shops of <DumbLink href="https://thewalters.org/">The Walters Art Museum</DumbLink> (600 N. Charles St., Baltimore, MD 21201) and <DumbLink href="https://artbma.org/">The Baltimore Museum of Art</DumbLink> (10 Art Museum Drive, Baltimore, MD 21218). If you are interested in stocking <b>A Trip to the Art Museum with Herbert the Hoodleoo</b> in your bookstore, museum gift shop, or wish to purchase a copy directly, please email <b>happyworldofhoodleoos[at]gmail.com</b> for purchasing specifications and information.
                </Paragraph>
                <Paragraph>
                <ImgContainer><img src={book} alt="Working images of A Trip to the Art Museum with Herbert the Hoodleoo" width="700px" style={{ margin: "5px" }}/></ImgContainer>
                </Paragraph>
                <Subtitle>
                    Book Specifications
                </Subtitle>
                <Paragraph>
                    <div>Format: Softcover</div>
                    <div>Style: Hand-drawn illustrated story</div>
                    <div>ISBN: 979-8-218-37322-1</div>
                    <div>Dimensions: 8"x8"</div>
                    <div>Length: 28 double-sided pages</div>
                    <div>Age range: 3-6</div>
                    </Paragraph>
            </Container>
        </Page>
        <Footer>
            © Copyright Peter Dayton, 2022-2024 |&nbsp;<Link to="/">Home</Link>
        </Footer>
    </div>
}

const Paragraph = styled.p`
    font-family: EB Garamond;
    line-height: 2em;
    text-indent: 3em;
    margin: 0 30px;

    li {
        text-indent: 0;
        margin-bottom: 10px;
    }
`;

export const Container = styled.div`
    --width: min(calc(100vw - 40px), 1000px);
    display: flex;
    width: var(--width);
    margin: 10px 10px 2vh 10px;
    justify-content: center;
    flex-direction: column;
`;

const Title = styled.h3`
    font-family: Jost;
    margin: 10px 30px;
    text-align: center;
`;

const Subtitle = styled.h3`
    font-family: Jost;
    margin: 10px 30px;
`;

export const Thing = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ImgContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        max-width: 100%;
    }
`;