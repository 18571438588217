import styled from "styled-components"
import { Footer, Header, HeaderTitle, Page, Link, TopButtonRow, ButtonLink } from "./common"
import { usePosts } from "./use_posts";

export const Appendix: React.FC = () => {
    const { posts } = usePosts(undefined);
    return <div>
        <Header>
            <HeaderTitle>The Happy World of Hoodleoos</HeaderTitle>
        </Header>
        <Page>
            <TopButtonRow>
                <ButtonLink 
                    to="/"
                >Home</ButtonLink>
                <ButtonLink 
                    to="/about"
                >About</ButtonLink>
                <ButtonLink 
                    to="/contact"
                >Contact</ButtonLink>
                <ButtonLink
                    to="/book"
                >Book</ButtonLink>
            </TopButtonRow>
            <Container>
                <Paragraph>
                    { posts?.map((post) => {
                        return <PostInfo key={post.id}>
                            <div><TitleLink to={`/${post.id}`}>{post.title}</TitleLink></div>
                            { post.info && <Info>{post.info}</Info> }
                        </PostInfo>;
                    })}
                </Paragraph>
            </Container>
        </Page>
        <Footer>
            © Copyright Peter Dayton, 2022-2024. |&nbsp;<Link to="/">Home</Link>
        </Footer>
    </div>
}

const TitleLink = styled(Link)`
    font-size: 20px;
    font-family: Jost;
    color: #2f2f32;
    margin-top: 5px;
    line-height: 20px;
`;

const Info = styled.p`
    margin: 0;
    line-height: 26px;
    padding-left: 1em;
`;

const Paragraph = styled.div`
    font-family: EB Garamond;
    line-height: 2em;
    margin: 0 30px;
    width: 100%;
`;

const PostInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Container = styled.div`
    --width: min(calc(100vw - 40px), 1000px);
    display: flex;
    width: var(--width);
    margin: 10px 10px 2vh 10px;
    justify-content: center;
`;