import styled from "styled-components";
import { Link as OriginalLink } from "react-router-dom";

export const Header = styled.div`
    position: fixed;
    width: 100vw;
    display: flex;
    padding: 5px 0;
    align-items: center;
    justify-content: center;
    // border-bottom: 1px solid #cfcfdd;
    top: 0;
    left: 0;
    font-family: Futura;
    color: #515154ce;
    background-color: #f4f4f4;
    box-shadow: 1px 1px 8px #f4f4f4;
    // background-color: #a5a5cf;
    // background-image: linear-gradient(314deg, #a4a9cb, #e2e3edf1);
    // color: #f1f1f1;

    // box-shadow: 1px 1px 8px #91919122;
    // text-shadow: 0 0 3px #362b7336;
`;

export const Footer = styled.div`
    margin-top: 35px;
    color: gray;
    display: flex;
    text-align: center;
    justify-content: center;
    font-family: Futura;
    font-size: 14px;
    margin-bottom: 10px;
`;

export const HeaderTitle = styled.h1`
    font-size: 21px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: Jost;
    color: #515154;
`;

export const Page = styled.div`
    margin: 10px;
    padding-top: max(min(10vh, 70px), 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DumbLink = styled.a`
    color: inherit;
`;

export const Link = styled(OriginalLink)`
    color: inherit;
`;

export const ButtonLink = styled(OriginalLink)`
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    color: #515154;
    font-family: Futura;
    border-radius: 11px;
    background: #f4f4f4;
    box-shadow:  5px 5px 13px #e0e0e0,
                -5px -5px 13px #ffffff;

                font-weight: normal;
                text-decoration: none;
                font-size: 14px;
`;

export const DumbButtonLink = styled.a`
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    color: #515154;
    font-family: Futura;
    border-radius: 11px;
    background: #f4f4f4;
    font-size: 14px;
    box-shadow:  5px 5px 13px #e0e0e0,
                -5px -5px 13px #ffffff;

                font-weight: normal;
                text-decoration: none;
`;


export const TopButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 10px;
`;

export const Button = styled.button`
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    color: #515154;
    font-family: Futura;
    border-radius: 11px;
    background: #f4f4f4;
    font-size: 14px;
    box-shadow:  5px 5px 13px #e0e0e0,
                 -5px -5px 13px #ffffff;

    &:disabled {
        cursor: unset;
        color: #858589;
        box-shadow:  5px 5px 13px #e0e0e090,
                     -5px -5px 13px #ffffff90;
    }

    &:active {
        background: #f4f4f4;
        box-shadow: inset 5px 5px 13px #e0e0e0,
                    inset -5px -5px 13px #ffffff;
    }
`;