import { useQuery } from "react-query";
import artworkURL from "./artwork.txt";
import { parseArtworkFile } from "./parse_artwork";

export function usePosts(postId: string | undefined) {
    const { data: allPosts, isLoading } = useQuery("artwork-registry", async () => {
        const file = await fetch(artworkURL);
        return parseArtworkFile(await file.text());
    });

    const posts = allPosts?.filter((work) => {
        return (
            (work.availableOn === undefined || work.availableOn < new Date())
            && !work.hidden
        );
    });

    const postIndex = posts 
        ? postId 
            ? posts.findIndex((post) => post.id === postId) 
            : posts.length - 1 
        : -1;

    const post = posts && posts[postIndex];

    const links = posts && {
        next: posts[postIndex + 1],
        previous: posts[postIndex - 1],
        first: posts[0],
        last: posts[posts.length - 1],
    };

    return { posts, post, links, isLoading };
}